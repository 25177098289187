import * as React from 'react'
import { Helmet } from 'react-helmet'
import LayoutService from '../templates/LayoutService'
import { StaticImage } from 'gatsby-plugin-image'
import { imgBox } from '../styles/components/boxes.module.scss'
import { bannerBlock, bannerBlockHeadline, bannerBlockTagline, bannerBlockTitleSm, pageContentBlock, pageContentMedia, pageCalloutBlock } from '../styles/components/blocks.module.scss'
import { hideBlockSM } from '../styles/components/helpers.module.scss'
import { list3 } from '../styles/components/lists.module.scss'
import '../styles/components/content.module.scss'
import { Link } from 'gatsby'

const DestinationsPage = () => {
  return (
    <LayoutService>
      <Helmet>
        <title>Private Party Boat Charter to Popular Destinations in Tampa Bay | Tampa Bay Boating Adventures</title>
        <meta name="description" content="We provide exclusive party boat charter services to popular destinations along the Tampa, FL coast including Beer Can Island, private beaches, exclusive sandbars and islands, and much more. Book yours online today!" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <header className='container'>
        <div className='row'>
          <div className='col-xxs-12'>
            <div className={bannerBlock}>
              <div className={imgBox}>
                <StaticImage
                  alt="A view of Armature Works from the water in Tampa Bay, Florida."
                  src="../images/banners/view-of-armature-works-from-water-in-tampa-bay-florida.jpg"
                />
              </div>
              <div className={bannerBlockHeadline}>
                <h1 className={bannerBlockTagline}>Party Boat Destinations in Tampa Bay, FL</h1>
                <span className={bannerBlockTitleSm}>Sights You'll See <br className={hideBlockSM}/>in Tampa</span>
              </div>
            </div>
          </div>
        </div>
      </header>
      <main className='container'>
        <div className='row'>
          <div className='col-xxs-12'>
            <div className={pageContentBlock}>
              <h2>Travel to many exclusive destinations in the Tampa Bay area on one of our private party boat charters.</h2>
              <h3>Our private party boat charters can take you to popular destinations in the Tampa Bay Area including Beer Can Island, Tampa Riverwalk, Fort De Soto, and many more!</h3>
              <div className={pageContentMedia}>
                <div className={imgBox}>
                  <StaticImage
                    alt="Beer Can Island and party boats in Florida."
                    src="../images/banners/beer-can-island-and-party-boats-in-florida.jpg"
                  />
                </div>
              </div>
              <p>Looking to get out on the water and explore unique parts of the Tampa Bay area that can only be accessed by boat? Want to have a blast drinking and partying with your friends while doing it? If so, it’s time to book a private party boat charter with Tampa Bay Boating Adventures. We can take you and your group to beautiful areas that can only be accessed via boat such as Pine Key (AKA Beer Can Island), local sandbars, private islands, and remote parts of Fort De Soto. We can also take you to popular party destinations like Tampa Riverwalk, Armature Works, Sparkman Wharf, and other areas that are accessible by land or sea if you want to grab some drinks or food. Whatever you’re looking to do in the Tampa Bay area, we can make it happen!</p>
              <hr />
              <h2>Pine Key (AKA Beer Can Island)</h2>
              <p>While the name of this island has recently been changed to Pine Key, locals still refer to it as Beer Can Island. This popular island is only accessible by boat and is known as a true party island. Our private party boat charter can take you here, where you’ll be surrounded by other young guys and gals who are drinking, dancing, playing games, and having a blast!</p>
              <hr />
              <h2>Tampa Riverwalk</h2>
              <p>This beautiful area in Tampa runs along the Hillsborough River and can be accessed via land or boat. If you and your friends want to dock here to visit any of the popular bars and restaurants, we can do that! Just come back when you’re ready and we’ll head off to the next destination!</p>
              <hr />
              <h2>Private Beaches</h2>
              <p>One of the most unique experiences you can have on our private party boat charters is visiting a private island. These hidden gems located around the Tampa Bay area feature white-sand beaches and make for great places to spend the day. One of the most popular private beaches that our party boat can take you to is an exclusive area of Fort De Soto!</p>
              <hr />
              <h2>Sandbars</h2>
              <p>Sandbars are great if you want to immerse yourself in the water while basking in the warm rays of a sunny day. Our captains know of some awesome sandbars in the Tampa Bay area, and we can anchor down there while you drink, dance, and party the day away.</p>
              <div className={pageCalloutBlock}>
                <p>All of our private party boat charters are BYOB! You can bring any alcohol you want along for the ride!</p>
              </div>
              <h2>Armature Works</h2>
              <p>Armature Works is a hallmark party spot in the Tampa Bay area and a great place to grab some drinks and food. Considered a “food hall,” this massive marketplace is filled with a variety of spots to get some grub and grab a drink. We can dock here for as long as you want while you and your party check it out.</p>
              <hr />
              <h2>Sparkman Wharf</h2>
              <p>This destination is a perfect place to spend some time. We can dock up here so you and your friends can explore everything that Sparkman Wharf has to offer. There are tons of bars in this area, as well as spots to grab a bite to eat. And, if the Tampa Bay Lightning happen to be playing a game that day, you can expect the area outside Amalie Arena to be filled with pre-gamers looking to party!</p>
              <hr />
              <h2>We can also take you to popular events in the Tampa Bay area.</h2>
              <p>While getting out on the water in the Tampa Bay area is great any time, there are some events throughout the year that should be experienced on the water. Watching the Gasparilla Parade from the water is an incredible way to experience this yearly Tampa tradition. There are also several holidays throughout the year that are synonymous with island parties, such as Memorial Day and the 4th of July. Popular events and holidays we can take you to include:</p>
              <ul className={list3}>
                <li>Gasparilla</li>
                <li>Memorial Day</li>
                <li>4th of July</li>
                <li>Labor Day</li>
                <li>Championship parades</li>
                <li>And More!</li>
              </ul>
              <p>These events are extremely popular, so you’ll want to <a href="https://fareharbor.com/embeds/book/tampabayboatingadventures/items/?full-items=yes" target="_blank" rel="noreferrer">sign up ASAP</a> to ensure you’ll get a boat.</p>
              <hr />
              <h2>Sign up for any of our private party boat charters today and explore a variety of destinations in the Tampa Bay area.</h2>
              <p>At Tampa Bay Boating Adventures, we want you to have an unbelievable experience on our private party boat charters. That’s why we will take you to your preferred local destination. Just <Link to="/contact">fill out our submission form</Link> or <a href="mailto:ahoy@tampabayboatingadventures.com">send us an email</a> to book your spot. After you’ve secured your spot, all you’ll need to do is pack up your cooler and choose which places you want to spend the day partying. We’ll see you soon!</p>
            </div>
          </div>
        </div>
      </main>
    </LayoutService>
  )
}

export default DestinationsPage